import { Maybe, PlaceConnectionEdge } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { Feature, FeatureCollection } from 'geojson'
import { SourceSpecification } from 'maplibre-gl'
import { LISTINGS_CLUSTER_MAX_ZOOM, LISTINGS_CLUSTER_RADIUS } from '../components/map/constants'

export function getEmptyFeatureCollection(): FeatureCollection {
  return { type: 'FeatureCollection', features: [] } as FeatureCollection
}

export function getEmptyFeatureCollectionSource(forClusters = false): SourceSpecification {
  const source: SourceSpecification = {
    type: 'geojson',
    data: getEmptyFeatureCollection(),

    // Really weird that we have to do this but have to specify that our "id" property will be the id property
    // of the feature in the source. If we don't specify this, the id values end up being undefined.
    // https://maplibre.org/maplibre-gl-js-docs/style-spec/sources/#geojson-promoteId
    promoteId: 'id',
  }

  if (forClusters) {
    source.cluster = true
    source.clusterRadius = LISTINGS_CLUSTER_RADIUS
    source.clusterMaxZoom = LISTINGS_CLUSTER_MAX_ZOOM
  }

  return source
}

export function mapApiPlacesForUI(places: Maybe<PlaceConnectionEdge>[]): FeatureCollection {
  const collection: FeatureCollection = getEmptyFeatureCollection()

  if (places) {
    collection.features.push(
      ...places.map((p: Maybe<PlaceConnectionEdge>) => {
        const { geoJson, id, nameLong } = p?.node || { geoJson: '', id: '', nameLong: '' }

        const feature: Feature = {
          type: 'Feature',
          geometry: JSON.parse(geoJson || ''),

          // Really important to have the id field in our properties because it will be used to set the id
          // property in the features in the map. It does NOT work by setting an id here outside the
          // properties because we use the promotedId in getEmptyFeatureCollectionSource().
          properties: { id, name: nameLong.split(',')[0] },
        }

        return feature
      })
    )
  }

  return collection
}

import { Feature, GeoJsonProperties, Geometry } from 'geojson'
import { Coordinate } from './coordinate'
import { LayerSpecification, LngLatBoundsLike, LngLatLike, MapMouseEvent, SourceSpecification } from 'maplibre-gl'

export type ZoomRangeToValue<T> = {
  range: [number, number]
  value: T
}

export enum MapSource {
  Boundaries = 'boundaries',
  Labels = 'labels',
  Clusters = 'clusters',
  ClusterBoundaries = 'cluster-boundaries',
  Hexagons = 'hexagons',
  Listings = 'listings',
  ListingClusters = 'listing-clusters',
}

export enum MapLayer {
  Boundary = 'boundary',
  BoundaryBorders = 'boundary-borders',
  Labels = 'labels',
  Clusters = 'clusters',
  ClusterCount = 'cluster-count',
  ClusterBoundary = 'cluster-boundary',
  Hexagons = 'hexagons',
  HexagonBorders = 'hexagon-borders',
  Listings = 'listings',
  ListingsClusters = 'listing-clusters',
  ListingsClustersCount = 'listing-clusters-count',
  SingleListing = 'single-listing',
}

export interface MapFilterChangeEventOptions {
  togglingDisplayType: boolean
}

export interface MapChangeEvent {
  zoom: number
  center: LngLatLike
  bounds: LngLatBoundsLike
}

export interface MapFilterEvent {
  zoom: number
  center: LngLatLike
}

export type MaplibreMouseEvent = MapMouseEvent & {
  features?: Feature<Geometry, GeoJsonProperties>[] | undefined
} & object

export enum ClusterType {
  NonExpanded = 'non-expanded',
  Listings = 'listings',
}

export enum MarkerState {
  Default = 'default',
  Hover = 'hover',
}

export type MaplibreLayerSpec = LayerSpecification & { source?: string | SourceSpecification }

export type MapMarker<TData> = {
  coordinate: Coordinate
  data?: TData
  state?: MarkerState
}

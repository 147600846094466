import turfBbox from '@turf/bbox'
import turfBboxPolygon from '@turf/bbox-polygon'
import transformScale from '@turf/transform-scale'
import turfCircle from '@turf/circle'
import turfCenter from '@turf/center'
import booleanContains from '@turf/boolean-contains'
import { point as turfPoint, Position, BBox, Feature as TurfFeature } from '@turf/helpers'
import { LngLatBoundsLike, MapGeoJSONFeature } from 'maplibre-gl'
import { cellToBoundary, polygonToCells } from 'h3-js'
import { FeatureCollection, Feature } from 'geojson'
import { GeoBounds, PlaceType } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { ClusterType, ZoomRangeToValue } from './types'
import {
  ZOOM_RANGES_TO_PLACETYPE,
  ZOOM_RANGES_TO_CLUSTER_RESOLUTION,
  DEFAULT_CLUSTER_RESOLUTION,
  DEFAULT_MAP_PLACETYPE,
  LNG_LAT_CONVERSION_PRECISION,
  MAX_HEXAGONS_FOR_RESOLUTION,
  MIN_CLUSTER_RESOLUTION,
  DEFAULT_ADDRESS_SEARCH_RADIUS,
} from './constants'
import { getEmptyFeatureCollection } from '../../api-mappings/feature-collection'

/**
 * ----------------------------------------------------------------------------------------------------------------
 *
 *              ██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗
 *              ██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝
 *              ██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗
 *              ██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║
 *              ╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝
 *               ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 *      This file should only contain utils that are NOT required for the full maplibre map. This lets us
 *      use these utils without loading the enitre maplibre library.
 *
 *      If you need to add a util that is used when the entire library is loaded, add it to the utils.ts file.
 *
 * ----------------------------------------------------------------------------------------------------------------
 */

/**
 * Returns true for clusters that have a type of Cluster, meaning the ones that are not yet expanded on the map.
 * @param feature The feature to check.
 * @returns True if this is a high-level cluster (one that hasn't been expanded yet). False otherwise.
 */
export function isNonExpandedCluster(feature: MapGeoJSONFeature) {
  return feature.properties['type'] === ClusterType.NonExpanded
}

/**
 * Returns the number of listings for a non-expanded cluster.
 * @param feature The feature to check.
 * @returns The number of listings for this cluster.
 */
export function nonExpandedClusterCount(feature: MapGeoJSONFeature) {
  return isNonExpandedCluster(feature) ? feature.properties['count'] : 0
}

export function getValueForZoom<T>(zoom: number, ranges: ZoomRangeToValue<T>[], fallback: T) {
  for (const rangeInfo of ranges) {
    const [min, max] = rangeInfo.range
    if (zoom >= min && zoom < max) {
      return rangeInfo.value
    }
  }

  return fallback
}

export function getClusterResolutionForZoom(zoom: number, zoomRanges = ZOOM_RANGES_TO_CLUSTER_RESOLUTION) {
  return getValueForZoom<number>(zoom, zoomRanges, DEFAULT_CLUSTER_RESOLUTION)
}

export function getPlaceTypeForZoom(zoom: number) {
  return getValueForZoom<PlaceType>(zoom, ZOOM_RANGES_TO_PLACETYPE, DEFAULT_MAP_PLACETYPE)
}

/**
 * Helper function to get the sw/ne coordinates from a bounds object.
 * Created this because it's a nightmare to have to cast everything all the time.
 */
export function getBoundsCoords(bounds: LngLatBoundsLike | null | undefined): [number, number, number, number] {
  if (!bounds) {
    return [0, 0, 0, 0]
  }

  // We need to handle both types of data. Either [LngLatLike, LngLatLike] or an instance of LngLatBounds.
  let _sw
  let _ne
  if (Array.isArray(bounds)) {
    _sw = bounds[0] as { lng: number; lat: number }
    _ne = bounds[1] as { lng: number; lat: number }
  } else {
    _sw = bounds._sw
    _ne = bounds._ne
  }

  const { lng: swLng, lat: swLat } = _sw
  const { lng: neLng, lat: neLat } = _ne

  return [swLng, swLat, neLng, neLat]
}

//
// Use 7 decimals of precision to store lat/lng values (e.g. - in the URL) because it gives us everything we need.
//
// "The seventh decimal place is worth up to 11 mm: this is good for much surveying and
// is near the limit of what GPS-based techniques can achieve."
//
// https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
//

/**
 * Converts the passed in lng or lat to a number with 7 decimals of precision.
 * @param lngOrLat The lng or lat to be converted.
 * @returns The lng or lat with 7 digits of precision (see precision comment above).
 */
export function preciseLngOrLat(lngOrLat: number | undefined): number {
  return lngOrLat !== undefined ? Number.parseFloat(lngOrLat.toFixed(LNG_LAT_CONVERSION_PRECISION)) : 0
}

/**
 * Converts the passed in bounds to a bounds object where each lng/lat has 7 decimals of precision.
 * @param bounds The bounds to be converted.
 * @returns A bounds where each lng/lat has 7 decimals of precision (see precision comment above).
 */
export function preciseBounds(bounds: LngLatBoundsLike): LngLatBoundsLike {
  const [swLng, swLat, neLng, neLat] = getBoundsCoords(bounds)
  const newSW = { lng: preciseLngOrLat(swLng), lat: preciseLngOrLat(swLat) }
  const newNE = { lng: preciseLngOrLat(neLng), lat: preciseLngOrLat(neLat) }

  return [newSW, newNE]
}

/**
 * Given a bounds in Lng/Lat, returns a closed polygon representing those bounds.
 * @param bounds The bounds to convert.
 * @returns A closed polygon for the bounds shape.
 */
export function getPolygonForBounds(bounds: LngLatBoundsLike) {
  const [swLng, swLat, neLng, neLat] = getBoundsCoords(bounds)

  // For outer rings, the points have to go countrer clockwise:
  // https://www.rfc-editor.org/rfc/rfc7946#section-3.1.6:~:text=exterior%20rings%20are%20counterclockwise
  return [
    [preciseLngOrLat(swLng), preciseLngOrLat(neLat)],
    [preciseLngOrLat(swLng), preciseLngOrLat(swLat)],
    [preciseLngOrLat(neLng), preciseLngOrLat(swLat)],
    [preciseLngOrLat(neLng), preciseLngOrLat(neLat)],
    [preciseLngOrLat(swLng), preciseLngOrLat(neLat)],
  ]
}

/**
 * Returns an array of longitude/latitude coordinates that represent the map bounds. Used by the back end
 * as the shape for any queries that depend on it.
 * @param bounds Map bounds to convert.
 * @returns An array of coordinates that the back end understands.
 */
export function getGeoShapeCoordinatesForBounds(bounds: LngLatBoundsLike | null | undefined) {
  if (!bounds) {
    return []
  }

  const [swLng, swLat, neLng, neLat] = getBoundsCoords(bounds)

  return [
    { longitude: preciseLngOrLat(swLng), latitude: preciseLngOrLat(neLat) },
    { longitude: preciseLngOrLat(swLng), latitude: preciseLngOrLat(swLat) },
    { longitude: preciseLngOrLat(neLng), latitude: preciseLngOrLat(swLat) },
    { longitude: preciseLngOrLat(neLng), latitude: preciseLngOrLat(neLat) },
  ]
}

export function getCircleForPointAndRadius(lng: number, lat: number, radius: number) {
  const point = turfPoint([lng, lat])
  const circle = turfCircle(point, radius, { units: 'kilometers' })

  return circle
}

/**
 * Given a lng/lat and a distance, returns a bounds that encapsulates that area.
 * @param lng Longitude of the center point.
 * @param lat Latitude of the center point.
 * @param distance The distance (in KM) to calculate from the center point.
 * @returns
 */
export function getBoundsForPointAndDistance(lng: number, lat: number, distance: number): LngLatBoundsLike {
  const point = turfPoint([lng, lat])
  const [minLng, minLat, maxLng, maxLat] = turfBbox(turfCircle(point, distance, { units: 'kilometers' }))
  const _sw = { lng: minLng, lat: minLat }
  const _ne = { lng: maxLng, lat: maxLat }

  getCircleForPointAndRadius(lng, lat, distance)

  return preciseBounds([_sw, _ne])
}

/**
 * Helper that gets the center [lng, lat] point of the passed-in GeoJson Feature.
 * @param feature The GeoJson feature we want to get the center of.
 * @returns The [lng, lat] position of the feature.
 */
export function getFeatureCenter(feature: unknown): Position {
  return turfCenter(feature as TurfFeature).geometry.coordinates
}

/**
 * Returns the h3Ids for the given zoom and bounds and also the adjusted resolution to use to fetch clusters
 * from the back end.
 * @param zoom The current map zoom level.
 * @param bounds The current map bounds.
 * @returns H3 cells for the given map bounds and an adjusted cluster resolution.
 */
export function getH3CellsAndAdjustedResolution(
  zoom: number,
  bounds: LngLatBoundsLike,
  zoomRanges?: ZoomRangeToValue<number>[]
): { h3Ids: string[]; resolution: number } {
  // We have a set of default zoom ==> cluster resolution values, but depending on the current window size, zoom
  // and map bounds, that default might be too big. So start with the default and then see what value returns
  // a number of hexagons that's smaller than the maximum allowed by the back end (currently 400).
  const defaultResolution = getClusterResolutionForZoom(zoom, zoomRanges)

  // Need to reverse the bounds polygon because H3 uses [lat, lng] format.
  const reversedPolygon = getPolygonForBounds(bounds).map(([lng, lat]) => [lat, lng])

  try {
    // Start by calculating the number of hexagons for the default zoom resolution.
    let h3Ids = polygonToCells(reversedPolygon, defaultResolution)
    if (defaultResolution === 1) {
      // Running hexagon calculations is expensive so only do it once if we're already at the lowest resolution.
      return { h3Ids, resolution: defaultResolution }
    }

    // Be conservative and only use 90% of the max polygons for a given resolution. This gives us some wiggle room
    // and shouldn't cause resolution exceptions on the back end.
    // https://unreserved-workspace.slack.com/archives/C04BG186VC7/p1700059676959989
    const maxHexagons = MAX_HEXAGONS_FOR_RESOLUTION * 0.9

    if (h3Ids.length >= maxHexagons) {
      // We are over the max resolution for a given zoom/bounds so adjust the resolution downwards.
      // We step by -1 until we are under the max number of hexagons that is supported by the back end.
      let resolution = defaultResolution
      for (; resolution >= MIN_CLUSTER_RESOLUTION; --resolution) {
        h3Ids = polygonToCells(reversedPolygon, resolution)
        if (h3Ids.length < maxHexagons) {
          return { h3Ids, resolution }
        }
      }
    }

    return { h3Ids, resolution: defaultResolution }
  } catch (e) {
    // polygonToCells can fail with an unknown error for certain polygons just just catch it to make sure
    // we don't crash. Error description here: https://github.com/uber/h3/issues/521
    // "Error: The operation failed but a more specific error is not available (code: 1)"

    console.error(`polygonToCells - zoom[${zoom}] bounds[${bounds}]`, e)

    // We should never get here but return a value
    return { h3Ids: [], resolution: 1 }
  }
}

/**
 * Returns a Feature collection for the hexagons that are calculated for the passed in zoom and bounds and also
 * the adjusted resolution to use when fetching clusters.
 * @param zoom The current map zoom.
 * @param bounds The current map bounds.
 * @returns A FeatureCollection containing hexagon Features used in the UI and an adjusted resolution.
 */
export function getHexagonsAndClusterResolution(
  zoom: number,
  bounds: LngLatBoundsLike,
  zoomRanges?: ZoomRangeToValue<number>[]
): { hexagons: FeatureCollection; resolution: number } {
  const { h3Ids, resolution } = getH3CellsAndAdjustedResolution(zoom, bounds, zoomRanges)
  const hexagons: FeatureCollection = getEmptyFeatureCollection()
  h3Ids.forEach((h3Index: string) => {
    hexagons.features.push({
      type: 'Feature',
      properties: { id: h3Index },
      geometry: { type: 'Polygon', coordinates: [[...cellToBoundary(h3Index, true)]] },
    })
  })

  return { hexagons, resolution }
}

/**
 * Helper to return whether the second bounds fits completely inside the first bounds.
 * Reference: https://turfjs.org/docs/#booleanContains
 *
 * @param outer The outer bounds that should contain the inner bounds.
 * @param inner The inner bounds that should be within the outer bounds.
 * @returns True if the second bounds fits completely inside the first bounds.
 */
export function boundsDoContain(outer: LngLatBoundsLike, inner: LngLatBoundsLike) {
  return booleanContains(
    turfBboxPolygon(getTurfBboxInputFromBounds(outer)),
    turfBboxPolygon(getTurfBboxInputFromBounds(inner))
  )
}

// TODO: Check where this pattern is used (e.g. - modules) and refactor to be able to use the common code.
export function getGeoFeatureFromQueryParam(param?: string | string[]): Feature | null {
  if (!param) {
    return null
  }

  // Exmample param: -75.67638,45.42408,25,KM,123%20Russell%20Ave%2C%20Ottawa%2C%20ON
  const values = decodeURIComponent(param as string).split(',')
  const lng = preciseLngOrLat(Number.parseFloat(values[0]))
  const lat = preciseLngOrLat(Number.parseFloat(values[1]))
  const distance = values.length > 2 ? Number.parseFloat(values[2]) : DEFAULT_ADDRESS_SEARCH_RADIUS

  return getCircleForPointAndRadius(lng, lat, distance)
}

/**
 * Converts a GeoBounds API object to a LngLatBoundsLike GeoJson object.
 * @param bounds The bounds to convert.
 * @returns A LngLatBoundsLike GeoJson object.
 */
export function getLngLatBoundsForGeoBounds(bounds: GeoBounds, boundsScale = 1.5): LngLatBoundsLike {
  // We expand the bounds by 50% default so to make sure that the features are all displayed in the new bounds.
  const originalPoly = turfBboxPolygon(getTurfBboxInputFromGeoBounds(bounds))
  const resizedPoly = transformScale(originalPoly, boundsScale)
  const [swLng, swLat, neLng, neLat] = turfBbox(resizedPoly)

  return [
    { lng: swLng, lat: swLat },
    { lng: neLng, lat: neLat },
  ]
}

/**
 * Gets a Feature representing the passed in GeoBounds.
 * @param bounds GeoBounds representation of the feature.
 * @returns TurfFeature that can be used to draw on the map.
 */
export function getBoundaryForGeoBounds(bounds: GeoBounds): TurfFeature {
  return turfBboxPolygon(getTurfBboxInputFromGeoBounds(bounds))
}

/**
 * Gets an input in BBox format to be used in the turf.js functions.
 * @param bounds The LngLatBound object to convert.
 * @returns A BBox input that follows the GeoJSON format
 */
function getTurfBboxInputFromBounds(bounds: LngLatBoundsLike): BBox {
  // +------------------------------ ne
  // |                                |
  // |         LngLatBoundsLike           |
  // |                                |
  // sw ------------------------------+

  // Format is [swLng, swLat, neLng, neLat]
  // https://datatracker.ietf.org/doc/html/rfc7946#section-5
  return getBoundsCoords(bounds) as BBox
}

/**
 * Gets an input in BBox format to be used in the turf.js functions.
 * @param bounds The GeoBounds object to convert.
 * @returns A BBox input that follows the GeoJSON format
 */
function getTurfBboxInputFromGeoBounds(bounds: GeoBounds): BBox {
  // [tlLng, tlLat] ---------------- ne
  // |                                |
  // |         GeoBounds              |
  // |                                |
  // sw ---------------- [brLng, brLat]
  const { lon: tlLng, lat: tlLat } = bounds.topLeft
  const { lon: brLng, lat: brLat } = bounds.bottomRight

  // Format is [swLng, swLat, neLng, neLat]
  // https://datatracker.ietf.org/doc/html/rfc7946#section-5
  return [tlLng, brLat, brLng, tlLat]
}

export function extendBounds(
  bounds: LngLatBoundsLike | undefined,
  lng: number,
  lat: number
): LngLatBoundsLike | undefined {
  if (bounds) {
    const [swLng, swLat, neLng, neLat] = getBoundsCoords(bounds)
    const newSwLng = Math.min(swLng, lng)
    const newSwLat = Math.min(swLat, lat)
    const newNeLng = Math.max(neLng, lng)
    const newNeLat = Math.max(neLat, lat)

    return [
      { lng: newSwLng, lat: newSwLat },
      { lng: newNeLng, lat: newNeLat },
    ]
  }

  return [
    { lng, lat },
    { lng, lat },
  ]
}
/**
 * Returns the meters per pixel for a given latitude and zoom level.
 * References:
 *  - https://stackoverflow.com/a/30773300
 *  - https://wiki.openstreetmap.org/wiki/Zoom_levels
 * @param latitude
 * @param zoomLevel The meters per pixel for the passed in latitude and zoom level.
 * @returns
 */
export function metersPerPixel(latitude: number, zoomLevel: number) {
  const earthCircumference = 40075017
  const latitudeRadians = latitude * (Math.PI / 180)

  return (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoomLevel + 9)
}

import { PlaceType } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { THE_PUMP } from '@unreserved-frontend-v2/api/constants'
import { KeyValuePair } from '@unreserved-frontend-v2/utils/types'
import { LngLatBoundsLike, Offset } from 'maplibre-gl'
import { ZoomRangeToValue } from './types'

export const DEFAULT_MAP_LOCATION = THE_PUMP // https://www.lieutenantspump.ca/
export const MAP_CENTER_CANADA = { lng: -106.346806, lat: 56.130389 }
export const MAP_ZOOM_CANADA = 4

// The normal map is the one used to highlight a given set of listings when doing pagination.
export const MAP_MIN_ZOOM = 3
export const DEFAULT_MAP_ZOOM = 10

// The full map is the one used to browse listings.
export const DEFAULT_FULL_MAP_ZOOM = 10

export const DEFAULT_CITY_MAP_ZOOM = 10
export const DEFAULT_LISTING_ZOOM = 15
export const DEFAULT_ZOOM_SPEED = 0.5
export const DEFAULT_BOUNDS_PADDING = 40
export const MAP_CHANGE_DEBOUNCE_TIME = 135
export const DEFAULT_MAP_PLACETYPE = PlaceType.MacroNeighbourhood
export const DEFAULT_BOUNDS_FETCH_COUNT = 1000
export const DEFAULT_BOUNDS_OFFSET: [number, number] = [0, 0]

export const FIT_TO_BOUNDS_NO_DURATION = 0
export const FIT_TO_BOUNDS_DEFAULT_DURATION = 500
export const FIT_TO_BOUNDS_GEOSEARCH_DURATION = 500

// When switching from Preview to Map mode, default to a 1KM radius to zoom in the map.
export const DEFAULT_ADDRESS_SEARCH_RADIUS = 1 /* KM */
export const DEFAULT_ADDRESS_MAP_ZOOM = 15

// Default map bounds for a reasonable area of Canada.
// Use these lat,lng points to confirm on Google maps.
// SW - 41.9515121, -136.092074
// NE - 60.7202527, -54.6583704
export const DEFAULT_MAP_BOUNDS_CANADA: LngLatBoundsLike = [
  { lng: -136.092074, lat: 41.9515121 },
  { lng: -54.6583704, lat: 60.7202527 },
]

// ----------------------------------------------------------------------
// Markers
// ----------------------------------------------------------------------
export const DEFAULT_MARKER_POPUP_OFFSET: Offset = 35
export const MOBILE_MARKER_POPUP_OFFSET: Offset = [0, 150]

// ----------------------------------------------------------------------
// Clusters
// ----------------------------------------------------------------------
export const DEFAULT_CLUSTER_RESOLUTION = 1
export const MIN_CLUSTER_RESOLUTION = 1
export const MAX_CLUSTER_RESOLUTION = 10
export const MAX_HEXAGONS_FOR_RESOLUTION = 400
export const MIN_CLUSTER_LISTING_COUNT = 15

export const MIN_ZOOM_FOR_CLUSTER_EXPAND = 14.5
export const MIN_ZOOM_FOR_CLUSTER_EXPAND_COUNT_1 = 9

export const MIN_ZOOM_FOR_CLUSTER_FORCED_EXPAND = 18

// Used when deciding to force zoom on a cluster.
export const FORCED_CLUSTER_ZOOM_RADIUS = 1 /* KM */
export const MAX_ZOOM_FOR_CLUSTER_FORCED_ZOOM = 12

// When clicking on a cluster that has a single listing.
export const MAX_ZOOM_FOR_ZOOM_ON_SINGLE_LISTING = 13
export const TARGET_ZOOM_ON_SINGLE_LISTING_CLICK = 16
export const SINGLE_LISTING_ZOOM_DURATION = 1000

// These settings apply to the clusters that are auto calculated for us by the map when we expand our
// own clusters that have less than MIN_CLUSTER_LISTING_COUNT listings in them.
export const LISTINGS_CLUSTER_MAX_ZOOM = 18
export const LISTINGS_CIRCLE_SIZE = 5
export const LISTINGS_CLUSTER_SIZE = 10

// Settings used when determining how much to expand the cluster bounds when zooming.
export const SMALL_CLUSTER_COUNT = 15
export const BOUNDS_SCALE_SMALL_CLUSTER = 1.6
export const BOUNDS_SCALE_NORMAL_CLUSTER = 1.1

//
// TODO:  We are capping this at 250 pre-fetched listings as not to return too much data.
//        This does mean that the number of clusters/listings on screen might not match
//        the facet count values but in theory this will only happen at lower zoom levels
//        and it wouldn't be realistic to see the difference anyway.
//
//        NOTE: This will completely change when we switch to loading listings on click.
//
export const LISTINGS_CLUSTER_RESULTS_COUNT = 400

// Make sure that the cluster radius is always bigger than the cluster size so that the cluster circles
// don't overlap.
export const LISTINGS_CLUSTER_RADIUS = 25

export const BOUNDS_CIRCLE_CONVERSION_STEPS = 64

//
// Use 7 decimals of precision to store lat/lng values (e.g. - in the URL) because it gives us everything we need.
//
// "The seventh decimal place is worth up to 11 mm: this is good for much surveying and
// is near the limit of what GPS-based techniques can achieve."
//
// https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
//
export const LNG_LAT_CONVERSION_PRECISION = 7

export const ZOOM_LEVELS: KeyValuePair<string>[] = []
for (let i = 3; i <= 20; ++i) {
  ZOOM_LEVELS.push({ key: i.toString(), value: i.toString() })
  ZOOM_LEVELS.push({ key: (i + 0.5).toString(), value: (i + 0.5).toString() })
}

export const RESOLUTIONS: KeyValuePair<string>[] = []
for (let i = 1; i <= 10; ++i) {
  RESOLUTIONS.push({ key: i.toString(), value: i.toString() })
}

export const PLACE_TYPE_TO_ZOOM: Record<PlaceType, number> = {
  [PlaceType.Country]: 3,
  [PlaceType.Region]: 6,
  [PlaceType.MetroArea]: 8,
  [PlaceType.City]: 10,
  [PlaceType.MacroNeighbourhood]: 12,
  [PlaceType.Neighbourhood]: 13,
  [PlaceType.SubNeighbourhood]: 16,
  [PlaceType.Street]: 14,
}

export const ZOOM_RANGES_TO_PLACETYPE: ZoomRangeToValue<PlaceType>[] = [
  { range: [0, 3], value: PlaceType.Country },
  { range: [3, 6], value: PlaceType.Region },
  { range: [6, 8], value: PlaceType.MetroArea },
  { range: [8, 9], value: PlaceType.City },
  { range: [9, 11], value: PlaceType.MacroNeighbourhood },
  { range: [11, 13], value: PlaceType.Neighbourhood },
  { range: [13, 16], value: PlaceType.SubNeighbourhood },
  { range: [16, 24], value: PlaceType.Street },
]

//
// The back end enforces a resolution between 1 to 10.
// https://h3geo.org/docs/core-library/restable/
//
export const ZOOM_RANGES_TO_CLUSTER_RESOLUTION: ZoomRangeToValue<number>[] = [
  { range: [0, 4.5], value: 2 },
  { range: [4.5, 5.5], value: 3 },
  { range: [5.5, 7.5], value: 4 },
  { range: [7.5, 9], value: 5 },
  { range: [9, 10], value: 6 },
  { range: [10, 11], value: 7 },
  { range: [11, 12.5], value: 8 },
  { range: [12.5, 13.5], value: 9 },
  { range: [13.5, 18], value: 10 },
  { range: [18, 24], value: 12 },
]
export const ZOOM_RANGES_TO_CLUSTER_RESOLUTION_MOBILE: ZoomRangeToValue<number>[] = [
  { range: [0, 5.5], value: 2 },
  { range: [5.5, 7], value: 3 },
  { range: [7, 8], value: 4 },
  { range: [8, 9.5], value: 5 },
  { range: [9.5, 11], value: 6 },
  { range: [11, 12.5], value: 7 },
  { range: [12.5, 13.5], value: 8 },
  { range: [13.5, 15], value: 10 },
  { range: [15, 24], value: 12 },
]
